:root {
    --main-text-color: rgba(255, 255, 255, 255);
    --main-text-color-hover: rgba(225, 225, 225, 128);
}

.navbar {
    background: rgba(0, 0, 0, 0.8);
    color: white;
}

.navbar .navbar-link {
    color: var(--main-text-color)!important;
    font-size: 14px;
}

.navbar .navbar-link:hover {
    color: var(--main-text-color-hover)!important;
}

.navbar .navbar-brand {
    color: var(--main-text-color)!important;
}

.navbar .navbar-brand:hover {
    color: var(--main-text-color-hover)!important;
}